import './App.css';
import { useState } from 'react'

let in_pause = false;

let fun2call = null;

function periodic() {
  if (fun2call == null || in_pause)
    return;
  fun2call();
}

setInterval(periodic, 1000);

function PeriodicCounter() {
  const [counter, incCounter] = useState(1);
  fun2call = () => { console.log(counter); incCounter(counter + 1) }
  return (
    <>
      Counter = {counter}
    </>
  )
}

let fun_update_image = null;
let image_url = "https://images.dog.ceo/breeds/retriever-curly/n02099429_458.jpg"

function retrieveDogURL() {
  fetch('https://dog.ceo/api/breeds/image/random')
    .then(response => response.json())
    .then(
      (data) => {
        image_url = data.message;
        if (fun_update_image == null || in_pause)
          return;
        fun_update_image()
      }
    );
}

setInterval(retrieveDogURL, 5000);

function PeriodicDOG() {
  const [counter, incCounter] = useState(1);
  // We are incrementing a counter just to change the state of the component.
  // Image will be updated based on a global variable image_url.
  fun_update_image = () => { incCounter(counter + 1) }
  return (
    <figure class="figure">
      <img src={image_url} class="figure-img img-fluid rounded" alt="..." />
      <figcaption class="figure-caption">{image_url.split('/').pop()}</figcaption>
    </figure>
  )
}



function MySwitch() {
  const [counter, incCounter] = useState(1);
  let toggle_pause = () => {
    in_pause = !in_pause;
    incCounter(counter+1)
  }
  return (
    <>
    
    <button type="button" class="btn btn-primary float" onClick={toggle_pause}>
      {in_pause ? 'Resume' : 'Pause'}
    </button>
    </>
  )
}

function App() {
  return (
    <div class="container">
      <nav class="navbar sticky-top navbar-light bg-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">Periodic API Demo</a>
          <MySwitch />
        </div>
      </nav>
      <div class="row">
        <div class="col">
          <div class="p-3 bg-light">API will be called every 5 seconds.</div>
        </div>
        <div class="col">
          <div class="p-3 border bg-light"><PeriodicDOG /></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
        <input type="text" class="form-control float" id="usr"></input>
        </div>
        <div class="col">
          
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h2>JavaScript Code</h2>
          Function retrieveDogURL is caller periodically every 5 seconds. It initiates an HTTP request. 
          After data arrived and were decoded a function to update a React component is called.
          <pre>
          {
            `
            let fun_update_image = null;
            let image_url = "https://images.dog.ceo/breeds/retriever-curly/n02099429_458.jpg"
            
            function retrieveDogURL() {
              fetch('https://dog.ceo/api/breeds/image/random')
                .then(response => response.json())
                .then(
                  (data) => {
                    image_url = data.message;
                    if (fun_update_image == null || in_pause)
                      return;
                    fun_update_image()
                  }
                );
            }
            
            setInterval(retrieveDogURL, 5000);
            
            function PeriodicDOG() {
              const [counter, incCounter] = useState(1);
              // We are incrementing a counter just to change the state of the component.
              // Image will be updated based on a global variable image_url.
              fun_update_image = () => { incCounter(counter + 1) }
              return (
                <figure class="figure">
                  <img src={image_url} class="figure-img img-fluid rounded" alt="..." />
                  <figcaption class="figure-caption">{image_url.split('/').pop()}</figcaption>
                </figure>
              )
            }
            `
          }
          </pre>
        </div>
      </div>
    </div>
  );
}

export default App;
